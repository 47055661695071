import noUiSlider from 'nouislider';
import wNumb from './wNumb';

export default new class SliderClass {
    constructor() {
        this.init();
    }

    init() {
        var slider = document.getElementById('slider-step');

        if(slider == null)
            return;

        if(slider && slider.noUiSlider){
            slider.noUiSlider.destroy();
        } 

        const input = slider.nextElementSibling;    

        noUiSlider.create(slider, {
            start: [0.0],
            step: 0.5,
            range: {
                'min': [0],
                'max': [10]
            },
            behaviour: 'drag',
            padding: [0],
            connect: 'lower',
            tooltips: [
                wNumb({
                    decimals: 1,
                    suffix: ' h'
                })
            ],
        });

        slider.noUiSlider.set([input.value]);

        slider.noUiSlider.on('update', function (values, handle) {
            input.value = wNumb({
                decimals: 1,
            }).from(values[handle])
        });
    }

    refresh() {

    }
}();


