import ReadSmore from '../../node_modules/read-smore/dist/index.esm'

export default new class ReadmoreClass {
    constructor() {
      //this.init();
    }

    init() {
        // target all read more elements with `js-read-smore` class
        const readMores = document.querySelectorAll('.js-read-smore')

        // Init
        ReadSmore(readMores).init();
    }
}