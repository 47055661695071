import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';

export default new class DatepickerClass {
    constructor() {
      //this.init();
    }

    init() {
        const selectors = document.querySelectorAll('[type="datepicker"]');

        [].forEach.call(selectors, function(el) {

            let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;

            let dt = el.value ? new Date(el.value.replace(pattern,'$3-$2-$1')) : new Date();

            new AirDatepicker(el, {
                locale: localeEn,
                selectedDates: [dt],
                isMobile: true,
                autoClose: true,
                dateFormat: 'dd.MM.yyyy',
                firstDay: 1
            });
        });
    }

    parseDate(string) {
        let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
        return new Date(string.replace(pattern,'$3-$2-$1'));
    }
}();





