import Choices from 'choices.js';

export default new class SelectClass {
    constructor() {
        this.init();
    }

    init() {
        document.querySelectorAll(".select2").forEach(function(element){
            const choices = new Choices(element, {
                searchEnabled: false,
                shouldSort: false
            });
        });
    }
}();