import feather from "feather-icons";
import SliderClass from "../modules/slider";
import DropzoneClass from "../modules/dropzone";
import EditorClass from "../modules/editor";
import LoaderClass from "../modules/loader";
import SelectClass from "../modules/select";
import DatepickerClass from "../modules/datepicker";
import ChronoClass from "../modules/chrono";
import ReadmoreClass from "../modules/readmore";
import Swal from 'sweetalert2';

const initialize = () => {

    ChronoClass.init();
    ReadmoreClass.init();

    addEventListener('click', (e) => {
        if(!e.target.matches('._copy-text')) return;

        e.preventDefault();
    });

    addEventListener('form:update', () => {

        const chatMessage = document.getElementById("chat-message");
        const chatContainer = document.getElementById("chat-container");

        if(null != chatContainer) {

            // scroll to down
            chatContainer.addEventListener('ajax:update', function() {
                scrollDownChat();
            });

            scrollDownChat();
        }

        if(null != chatMessage) {
            // send from Enter
            chatMessage.addEventListener("keydown", function(e) {
                if (e.key === 'Enter' && !e.shiftKey) {

                    document.querySelector("._enter").click();

                    e.preventDefault();
                    return false;
                }
            });
        }

        
    });


    addEventListener('ajax:always', () => {
        SelectClass.init();
        feather.replace();
    });

    addEventListener('ajax:update-complete', (event) => {

        let { el, handler } = event.detail.context;

        if(handler == 'onSubmit') return;

        LoaderClass.stop();
        EditorClass.init();
        DropzoneClass.init();
        SliderClass.init();
        DatepickerClass.init();
        SelectClass.init();
        ChronoClass.init();
        ReadmoreClass.init();
        feather.replace();
    });

    addEventListener('ajax:promise', (event) => {

        let { el, handler } = event.detail.context;

        if(el != document && handler != 'onSubmit') {
            LoaderClass.start();
        }
        
        document.querySelectorAll('.is-invalid').forEach(function(element) {
            element.classList.remove('is-invalid');
            element.closest('.form-group').querySelector('.invalid-feedback').innerHTML = '';
        });
    });

    addEventListener('ajax:invalid-field', (event) => {
        let { element, fieldName, errorMsg, isFirst } = event.detail;

        if(element.nodeName === 'SELECT') {
            element = element.closest('.choices');
        }

        element.classList.add('is-invalid');
        element.closest('.form-group').querySelector('.invalid-feedback').insertAdjacentHTML('afterbegin', errorMsg);
    });

    addEventListener('ajax:error-message', function(event) {

        let { message } = event.detail;

        if(message)
            Swal.fire({
                text: message,
                icon: 'warning',
                timer: 2000,
                showConfirmButton: false,
            });

        event.preventDefault();
    });
}

let scrollDownChat = () => {
    let wrapper = document.getElementById("myModal");
    wrapper.scrollTop = wrapper.scrollHeight;
}

addEventListener("page:loaded", () => initialize());

window.LoaderClass = LoaderClass;