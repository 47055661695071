import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;
 
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: 'eu',
    forceTLS: true,
    authEndpoint: "/dashboard/auth"
});