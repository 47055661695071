import ExploreEditor from 'explore-editor'
import plugins from 'explore-editor/lib/plugins';

export default new class EditorClass {
    constructor() {
        this.init();
    }

    init() {
        let input = document.querySelector('input[name=description]');

        let element = document.querySelector('#sample');

        if(element == null)
            return;

        if(element.nextElementSibling != null && element.nextElementSibling.matches('.meta-explore-editor')){ 
            return;
        }    

        let editor = ExploreEditor.create(element, {
            width : '100%',
            plugins: plugins,
            toolbarItem: [
                ['formatBlock','bold', 'underline', 'italic', 'strike'],
                ['link', 'horizontalRule'],
                ['showBlocks', 'codeView', 'fullScreen'],
            ],
        });
        
        editor.setContents(input.value);

        if(typeof element !== 'undefined'){
            addEventListener('ajax:setup', () => {
                input.value = editor.getContents();
            });
        }
    }
}();