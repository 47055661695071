export default new class LoaderClass {
    constructor() {
      
    }

    start() {
        const html = '<div class="position-fixed w-100 h-100 loading-bg"><div class="position-fixed loading"><div class="text-center"><div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status"></div></div></div></div>';

        const wrapper = document.getElementById('wrapper');

        if(null == wrapper) return;
    
        wrapper.insertAdjacentHTML('afterbegin', html);
    }

    stop() {
        const loader = document.querySelector('.loading-bg');

        if(loader == null)
            return;

        loader.remove();
    }
}();