import Dropzone from "dropzone";
import feather from "feather-icons";

export default new class DropzoneClass {
    constructor() {
      this.init();
    }

    init() {
        let id = "#dropzonejs";
        let dropzone = document.querySelector(id);
        let url = document.querySelector(`button[data-request="onSubmit"]`);

        if(dropzone == null)
            return;
        // set the preview element template
        var previewTemplate = document.querySelector('#uploaderTemplate');

        var myDropzone = new Dropzone(id, { // Make the whole body a dropzone
            url: url.getAttribute('data-request-url'), // Set the url for your upload script location
            parallelUploads: 20,
            maxFilesize: 1, // Max filesize in MB
            paramName: 'file_data',
            previewTemplate: previewTemplate.innerHTML,
            previewsContainer: id + " .dropzone-items", // Define the container to display the previews
            clickable: id + " .dropzone-select" // Define the element that should be used as click trigger to select files.
        });

        myDropzone.on("addedfile", function (file) {
            // Hookup the start button

            /*
            const dropzoneItems = dropzone.querySelectorAll('.dropzone-item');
            dropzoneItems.forEach(dropzoneItem => {
                dropzoneItem.style.display = '';
            });
            */

            //console.log(file);
            feather.replace();
        });

        // Update the total progress bar
        myDropzone.on("totaluploadprogress", function (progress) {
            /*
            const progressBars = dropzone.querySelectorAll('.progress-bar');
            progressBars.forEach(progressBar => {
                progressBar.style.width = progress + "%";
            });
            */
        });

        myDropzone.on("sending", function (file, xhr, formData) {
            // Show the total progress bar when upload starts
            const progressBars = dropzone.querySelectorAll('.progress-bar');
            progressBars.forEach(progressBar => {
                progressBar.style.opacity = "1";
            });

            const sessionKey = document.querySelector('#modal-form input[name="_session_key"]').value;

            console.log(sessionKey);
            
            formData.append('_session_key', sessionKey);
            formData.append('id', document.getElementById('form').getAttribute('data-id'));

            xhr.setRequestHeader('X-OCTOBER-REQUEST-HANDLER', 'uploader::onUpload');
        });

        myDropzone.on("success", function (file, response) {
            file.previewElement.setAttribute('data-id', response.id);
            file.previewElement.querySelector('.dropzone-delete').setAttribute('data-request-data', JSON.stringify({
                "file_id":  response.id
            }));
            file.previewElement.querySelector('.dropzone-delete').setAttribute('data-request-success', 'removeAttachment(' + response.id + ')');
        });


        // Hide the total progress bar when nothing"s uploading anymore
        myDropzone.on("complete", function (progress) {

            console.log('uploaded');

            /*
            const progressBars = dropzone.querySelectorAll('.dz-complete');

            setTimeout(function () {
                progressBars.forEach(progressBar => {
                    progressBar.querySelector('.progress-bar').style.opacity = "0";
                    progressBar.querySelector('.progress').style.opacity = "0";
                });
            }, 300);
            */
        });

        myDropzone.on("removedfile", function (file) {
            console.log('removed');
        });
    }
}();

addEventListener("DOMContentLoaded", function() { 
    addEventListener('click', (e) => {
        if (!e.target.matches('.dropzone-delete')) return;
    });
});