export default class Timer {
    constructor (element) {
      this.isRunning = false;
      this.startTime = 0;
      this.overallTime = 0;
  
      this.element = element;

      this.init();
    }

    init () {
        this.parseTime();
    }

    parseTime () {

      if(!this.element.innerText) return;

      let noSpaces = this.element.innerText.replace(/ /g,'');

      let overallTimeMinutes = noSpaces.match(/\d+\s?\w/g).reduce((acc, cur, i) => {
            var multiplier = 1;
            console.log(cur, acc);
            switch (cur.slice(-1)) {
                case 'h':
                    multiplier *= 60;
                case 'm': 
                    return ((parseInt(cur)?parseInt(cur):0) * multiplier) + acc;
            }
            return acc;
      }, 0);

      this.overallTime = overallTimeMinutes * 60 * 1000;
    }
  
    _getTimeElapsedSinceLastStart () {
      if (!this.startTime) {
        return 0;
      }
    
      return Date.now() - this.startTime;
    }
  
    start (element) {
      if (this.isRunning) {
        return console.error('Timer is already running');
      }
  
      this.isRunning = true;
  
      this.startTime = Date.now();
    }
  
    stop () {
      if (!this.isRunning) {
        return console.error('Timer is already stopped');
      }
  
      this.isRunning = false;
  
      this.overallTime = this.overallTime + this._getTimeElapsedSinceLastStart();
    }
  
    reset () {
      this.overallTime = 0;
  
      if (this.isRunning) {
        this.startTime = Date.now();
        return;
      }
  
      this.startTime = 0;
    }
  
    getTime () {
      if (!this.startTime) {
        return 0;
      }
  
      if (this.isRunning) {
        return this.overallTime + this._getTimeElapsedSinceLastStart();
      }
  
      return this.overallTime;
    }

    getValue () {
      const d = Number(this.getTime() / 1000);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);
    
      let result = [];
      result[0] = h ? h + " h" : "";
      result[1] = m ? m + " min" : "";
      /*result[2] = s ? s + " sec" : "";*/

      return result.join(' ');
    }

    run () {
      this.start();

      setInterval(() => {
        this.element.innerText = this.getValue();
      }, 1000);
    }
};