import EinsModal from 'eins-modal';

export default new class ModalClass {
    constructor() {
      this.init();
    }

    init() {

        // with options
        const options = {
            openTransition: 'slideRightBigIn',
            openDuration: 200,
            closeTransition: 'slideRightBigOut',
            closeDuration: 200,
            backdropClose: false,
            multiple: true,
            wait: false
        }

        const modal = document.getElementById('myModal');

        if(null == modal) return;

        const form = document.getElementById('form');

        addEventListener('click', function(e){
            if (!e.target.matches('.form-open')) return;

            const request   = e.target.getAttribute('request');
            const element   = e.target.getAttribute('element');
            const query     = e.target.getAttribute('query');

            if(!request) return;

            const attributes = e.target.dataset;
            const { id } = attributes;

            if(typeof id !== "undefined")
                form.setAttribute('data-id', id);

            modal.modal('show', options);

            oc.ajax('forms::onAjax', {
                data: {
                    'data': JSON.parse(JSON.stringify(attributes)),
                    'request': request,
                    'element': element,
                    'query': query
                },
                success: function(data) {
                    this.success(data).done(() => {
                        dispatchEvent(new CustomEvent("form:update", {
                            bubbles: true
                        }));
                    });
                }
            });
        });

        addEventListener('click', function(e){

            if (!e.target.matches('.form-close')) return;

            modal.modal('hide');
        });

        modal.addEventListener('hide.eins.modal', function(){
            while(form.firstChild){
                form.removeChild(form.firstChild);
            }
        });

        addEventListener('form:success', function() {
            modal.modal('hide');
        });
    }
        
}();